.sgh-category {
  padding: 0.3rem 1.6rem 0.3rem 0.4rem;
  border-radius: 4rem;
  border: #222222 1px solid;
  @screen lg {
    &:hover {
      text-decoration: underline;
      opacity: 1;
    }

    &__sale-category:hover {
      color: $red-berry-2;
    }
  }

  img {
    height: 3.2rem;
    width: 3.2rem;
  }
  &__img-container {
    height: 3.2rem;
    width: 3.2rem;
    margin-right: 0.7rem;
  }
  &--selected {
    background-color: #222222;
  }
}

.sgh-header--has-db ~ .sgh-grid {
  margin-top: 0;

  @screen lg {
    margin-top: 2.4rem;
  }
}
@screen lg {
  .sgh-header--has-db + .sgh-description + .sgh-grid {
    margin-top: 0;
  }
}
