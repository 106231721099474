$height-global-banner-desktop: 4rem;
$height-global-banner-mobile: 4.8rem;
$height-main-navigation: 6rem;
$height-filters-desktop: 6.5rem;
$height-filters-mobile: 5.7rem;
$height-dashbutton-desktop: 8.9rem;
$height-dashbutton-mobile: 7.6rem;

body.bodyPlp {
  padding-top: 0;

  &.has-benefitbar {
    padding-top: 0;
  }

  &.scroll-down-animate {
    .sgh-main-menu,
    .sgh-dash-buttons,
    .sgh-header__content--filters {
      animation: scrollDown 0.3s linear !important;
      animation-fill-mode: forwards !important;
    }
    .sgh-header__content--filters {
      &.sgh-header__content {
        top: $height-global-banner-desktop + $height-filters-desktop;
        @screen lgr {
          top: $height-global-banner-mobile + $height-filters-mobile;
        }
      }
    }

    .sgh-plp-container {
      transition: padding 0.3s linear;
    }
  }

  .sgh-header {
    position: static;
    &-top {
      z-index: 2;
    }
    @screen mdr {
      &.country-selector-layer {
        z-index: auto;
      }
    }
  }

  &.filters__fixed {
    .sgh-header {
      &__content--filters {
        position: fixed;
        top: $height-global-banner-desktop;
        right: 0;
        left: 0;
        z-index: 10000;
        @screen lgr {
          top: $height-global-banner-mobile;
        }
      }
    }
    &:not(.header-scroll-up) {
      .sgh-dash-buttons {
        animation: scrollDown 0.3s linear !important;
        animation-fill-mode: forwards !important;
        position: fixed;
        right: 0;
        left: 0;
        z-index: 5;
        top: $height-global-banner-desktop;
        box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.1);
        @screen lgr {
          top: 0;
        }
      }
    }

    &.header-scroll-up {
      .sgh-main-menu,
      .sgh-dash-buttons {
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1;
      }
      .sgh-main-menu,
      .sgh-dash-buttons,
      .sgh-header__content--filters {
        animation: scrollTop 0.3s linear;
        animation-fill-mode: forwards;
      }
      .sgh-main-menu {
        top: $height-global-banner-desktop;
        @screen lgr {
          top: $height-global-banner-mobile;
        }
      }
      .sgh-dash-buttons {
        top: $height-global-banner-desktop + $height-main-navigation;
        box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.1);
        @screen lgr {
          top: $height-global-banner-mobile + $height-main-navigation;
        }
      }
      .sgh-header {
        &__content--filters {
          top: $height-global-banner-desktop + $height-main-navigation;
          @screen lgr {
            top: 10rem;
          }
        }
      }
      &.has-filters {
        .sgh-dash-buttons {
          top: 16.1rem;
          @screen lgr {
            top: 14.9rem;
            &.is-multiline {
              top: 17rem;
            }
            &.hasTwentyFourHoursFilters {
              top: 20.5rem;
            }
          }
        }
      }
    }
    &.header-scroll-up-container {
      .sgh-plp-container {
        padding-top: $height-main-navigation;
        transition: padding 0.3s linear;
      }
      &.has-dashButtons {
        .sgh-plp-container {
          padding-top: $height-main-navigation + $height-dashbutton-desktop;
        }
      }
    }
    /* stylelint-disable no-duplicate-selectors */
    &:not(.header-scroll-up) {
      &.has-filters {
        .sgh-dash-buttons {
          top: $height-global-banner-desktop + $height-main-navigation;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .sgh-main-menu__wrapper {
      margin-top: 0;
    }
  }
}
sgh-header-top-controller {
  display: none;
}
@keyframes scrollDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes scrollTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
