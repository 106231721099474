.sgh-plp {
  &--mobile {
    .item {
      .catalog-item {
        .details {
          .sgh-nw-red {
            color: $red-berry !important;
            font-size: 1.6rem !important;
            font-weight: 500 !important;
            margin-left: 5px;
          }

          span.listPrice {
            color: $emperor !important;
          }
        }
      }
    }
  }

  &--desktop {
    margin-top: 60px;

    .item.plp {
      .catalog-item {
        .details {
          .original-price {
            margin-right: 65px !important;
            color: $emperor !important;
          }

          .sale-price {
            color: $red-berry !important;
            font-weight: 500;
          }
        }
      }
    }
  }
}
