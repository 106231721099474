.sgh-filter-by {
  height: calc(100vh - 96px);
  min-width: inherit;

  &__container {
    flex: 1 0 auto;

    &--hidden {
      @include loading-content();
      background-color: $white;

      &:after {
        opacity: 0.5;
      }
    }
  }

  &__chanel {
    .sortBy {
      display: none;
    }

    .sgh-filter-item {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $alto;
    }
    .sgh-filter-by__selected-label {
      display: inline-block;
    }
  }
}
