
@import '../../../styles/mixins/mixins';

#header-content-skeleton {
  .sgh-plp {
    &__filters,
    &__title {
      height: 3rem;
      width: 20%;
      @include skeleton-animation;
    }
    @screen lgr {
      &__title {
        width: 30%;
      }
    }
    @screen mdr {
      &__title {
        width: 60%;
      }
    }
  }
}
