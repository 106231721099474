
@import '../../../styles/variables';

.container {
  .switch-container {
    width: 6.4rem;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 4.7rem;
  height: 2.4rem;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray-2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.6rem;
  width: 1.6rem;
  left: 0.4rem;
  bottom: 0.4rem;
  background-color: $white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $royal-blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(2.4rem);
  -ms-transform: translateX(2.4rem);
  transform: translateX(2.4rem);
}

.slider.round {
  border-radius: 1.6rem;
}

.slider.round:before {
  border-radius: 50%;
}
