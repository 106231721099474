
@import '../../../../styles/load-more/load-more';
.sgh-load-more {
  content-visibility: auto;
  contain-intrinsic-size: auto none;
}

.animation-loader {
  height: 44px;
  width: 44px;
}

button{
 height:4rem;
 font-size:1.4rem;
}
