/* stylelint-disable */
#plp.sgh-plp .sgh-plp__filter {
  padding: 0 20px;
  border: 0;
  box-shadow: none;
  border-radius: 0;
}

#plp.sgh-plp .sgh-plp__filter .panel-heading {
  padding: 0;
  background: none;
  border-bottom: 1px solid $alto;
}

#plp.sgh-plp .sgh-plp__filter .panel-title {
  font-weight: 300;
}

#plp.sgh-plp .sgh-plp__filter .panel-title > a {
  position: relative;
  display: block;
  padding: 16px;
}

#plp.sgh-plp .sgh-plp__filter .panel-title > a:after {
  content: '\e901';
  position: absolute;
  right: 16px;
  font-family: 'sgh';
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
}

#plp.sgh-plp .sgh-plp__filter .panel-title > a[aria-expanded='true']:after {
  content: '\e907';
  font-family: 'sgh';
}

#plp.sgh-plp .sgh-plp__filter--cmd {
  padding: 13px 20px 26px;
  line-height: 2rem;
}

#plp.sgh-plp .sgh-plp__filter--cmd .icon-filter {
  font-size: 1.8rem;
}

#plp.sgh-plp .sgh-plp__filter--cmd .filter__status {
  display: inline-block;
  color: $black;
  font-size: 1.6rem;
}

#plp.sgh-plp .sgh-plp__filter--cmd .filter__status__count {
  color: $emperor;
  font-size: 1.4rem;
  margin-left: 3px;
}

#plp.sgh-plp .sgh-plp__filter--cmd .filter__clear {
  float: right;
}

#plp.sgh-plp .sgh-plp__filter--cmd .filter__clear a {
  text-decoration: underline;
}

#plp.sgh-plp .sgh-plp__filter--pre .panel-heading {
  padding: 16px 0;
}

#plp.sgh-plp .sgh-plp__filter--pre .sgh-plp__filter__name {
  font-weight: 500;
}

#plp.sgh-plp .sgh-plp__filter--pre .sgh-plp__filter__options {
  display: block;
  height: auto !important;
  border-bottom: 0;
}

#plp.sgh-plp .sgh-plp__filter--pre .sgh-plp__filter__options .panel-section ul {
  font-size: 1.6rem;
  font-weight: 300;
  text-transform: none;
}

#plp.sgh-plp .sgh-plp__filter__options {
  padding: 0 16px;
  margin-top: -1px;
  background: $white;
  border-bottom: 1px solid $alto;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section {
  padding-top: 0;
  border-bottom: 1px solid $alto;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section:last-child {
  border-bottom: none;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section h5 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  padding-top: 15px;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul {
  font-size: 1.2rem;
  text-transform: uppercase;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul li {
  position: relative;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul li a {
  display: inline-block;
  padding: 7px 0;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul li a.selected {
  position: relative;
  width: 174px;
  padding: 5px 26px 5px 9px;
  border: 1px solid $dusty-gray-2;
  border-radius: 17px;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul li a.selected:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 5px;
  width: 20px;
  height: 20px;
  background: url('../../static-cm/images/ico-close.png') no-repeat;
  transform: translateY(-50%);
  background-size: contain;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul li.disabled a {
  opacity: 0.6;
  cursor: default;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul li.disabled:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul.color-list {
  padding: 11px 0 0;
  margin-bottom: 15px;
  margin-left: -10px;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul.color-list li {
  margin-bottom: 5px;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul.color-list li a {
  position: relative;
  padding: 5px 26px 5px 9px;
  border: 1px solid transparent;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul.color-list li a.selected {
  width: 174px;
  border: 1px solid $dusty-gray-2;
  border-radius: 17px;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul.color-list li a.selected:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 5px;
  width: 20px;
  height: 20px;
  background: url('../../static-cm/images/ico-close.png') no-repeat;
  transform: translateY(-50%);
  background-size: contain;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section ul.color-list .color-sample__container {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 6px;
}

#plp.sgh-plp
  .sgh-plp__filter__options
  .panel-section
  ul.color-list
  .color-sample__container
  .color-sample {
  position: absolute;
  top: 0;
  width: 16px;
  margin-left: -50%;
  margin-top: 0;
}

#plp.sgh-plp
  .sgh-plp__filter__options
  .panel-section
  ul.color-list
  .color-sample__container
  .color-sample
  + .color-sample {
  margin-left: 50%;
}

#plp.sgh-plp .sgh-plp__filter__options .panel-section h5 + ul {
  padding-left: 18px;
}

#plp.sgh-plp .sgh-plp__filter__options .subnavprice {
  position: relative;
  left: 0;
  height: 64px;
  margin-top: -10px;
}

#plp.sgh-plp .sgh-plp__filter__options .ui-slider-horizontal .ui-slider-range {
  position: relative;
}

#plp.sgh-plp .sgh-plp__filter__options #valueHolder {
  padding-top: 4px;
}

#plp.sgh-plp .sgh-plp__filter__options #valueHolder div {
  position: relative;
  top: auto !important;
  left: auto !important;
  float: left;
  width: auto;
}

#plp.sgh-plp .sgh-plp__filter__options #valueHolder #sliderMax {
  right: auto !important;
  text-align: right;
  float: right;
  margin-right: 42px;
  z-index: 0;
}

.iphone {
  &.landscape {
    .sgh-filter-by {
      overflow: visible;
    }
  }
}

.sgh-filters-name {
  @include actaHeadlineFontTitle();
}
