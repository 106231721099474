.item.plp {
  .original-price {
    color: $dove-gray;
    font-size: 1.4rem;
    margin-right: 8px;
  }

  .sale-price {
    color: $tamarillo;
    font-size: 1.4rem;
  }
}
