
.sgh-filter-item-toggle {
  &-element {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    &.disabled {
      opacity: 0.3;
    }
  }

}

