@import '../utils/utils';
$height-global-banner-desktop: 4rem;
$height-global-banner-mobile: 4.8rem;
$height-filters-desktop: 6.5rem;
$height-filters-mobile: 6rem;
$height-bannerfeed-desktop: 7.5rem;
$height-bannerfeed-mobile: 10.3rem;
$height-dashbutton-desktop: 7.2rem;
$height-dashbutton-mobile: 7.6rem;

.skeleton {
  .benefitbar {
    height: $height-global-banner-desktop;
    width: 100%;
    display: flex;
    align-items: center;
    @screen lgr {
      height: $height-global-banner-mobile;
    }

    .text {
      width: 30%;
      margin: 0 auto;
      height: 1.4rem;
      @include skeleton-animation;
      @screen lgr {
        width: 95%;
      }
    }
  }
  .filters {
    height: $height-filters-desktop;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.5rem;
    @screen lgr {
      height: $height-filters-mobile;
      padding: 1.6rem;
    }
    .description {
      width: 20%;
      height: 2.8rem;
      @include skeleton-animation;
      @screen lgr {
        width: 50%;
        height: 2rem;
      }
    }
    .filter {
      display: flex;
      &-name {
        display: flex;
        margin-left: 4rem;
        @screen lgr {
          &:last-child {
            display: none;
          }
        }
      }
      &-description {
        width: 4rem;
        height: 1.6rem;
        @include skeleton-animation;
      }
      &-icon {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 10rem;
        margin-left: 1rem;
        @include skeleton-animation;
      }
    }
  }
  .banner-feed {
    height: $height-bannerfeed-desktop;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 2.5rem;
    @screen lgr {
      height: $height-bannerfeed-mobile;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 1.6rem;
    }
    .brand {
      &-logo {
        width: 7.5rem;
        height: 5rem;
        @include skeleton-animation;
        @screen lgr {
          height: 7.5rem;
        }
      }
      &-description {
        height: 1.4rem;
        width: 33%;
        margin: 0 2rem;
        @include skeleton-animation;
        @screen lgr {
          width: 100%;
          margin: 1rem 0 0;
        }
      }
    }
  }
  .dashbutton {
    height: $height-dashbutton-desktop;
    display: flex;
    justify-content: space-around;
    .button {
      width: 50%;
      gap: 1.2rem;
      display: flex;
      align-items: center;
      @screen lgr {
        width: 100%;
        padding: 0 1.2rem;
      }

      li {
        width: 25rem;
        height: 4rem;
        border-radius: 5rem;
        @include skeleton-animation;
        @screen lgr {
          width: 100%;
          &:first-child,
          &:last-child {
            display: none;
          }
        }
      }
    }
    @screen lgr {
      height: $height-dashbutton-mobile;
    }
  }
  .dynamic-banner {
    height: 60vh;
    margin: 1.9rem 0;
    background-color: $wild-sand;
  }
  .plp-tile-container {
    .plp-tile {
      width: 50%;
      margin-bottom: 2.7rem;
      @screen sm {
        width: 33.333333%;
        margin-bottom: 7rem;
      }
      &-image,
      &-brand,
      &-model,
      &-color {
        background-color: $wild-sand;
      }
      &-brand,
      &-model,
      &-color {
        height: 1rem;
        margin-bottom: 0.75rem;
      }
      &-image {
        position: relative;
        padding-top: 73.33%;
      }
      &-brand {
        margin-top: 2rem;
        margin-bottom: 1.2rem;
        width: 91.666667%;
      }
      &-model {
        width: 41.666667%;
      }
      &-color {
        width: 75%;
      }
    }
  }
}
