
@import '../../../styles/variables.scss';
.sgh-toggle {
  &-wrapper {
    &.primary {
      --toggle-text-color: #{$alice-blue};
      --toggle-bg-color-disabled: #{$white};
      --toggle-bg-color-enabled: #{$black};
      --toggle-border-color: #{$black};
      --toggle-thumb-color-enabled: #{$alabaster};
      --toggle-thumb-color-disabled: #{$silver-sand};
    }
    &.secondary {
      --toggle-text-color: #{$mine-shaft};
      --toggle-bg-color-disabled: #{$black};
      --toggle-bg-color-enabled: #{$white};
      --toggle-border-color: #{$mine-shaft};
      --toggle-thumb-color-enabled: #{$alabaster};
      --toggle-thumb-color-disabled: #{$silver-sand};
    }
    &.big {
      --toggle-height: 4rem;
      --toggle-thumb-margin: 0.4rem;
      --toggle-min-width: 4.4rem;
    }
    &.small {
      --toggle-height: 2.4rem;
      --toggle-thumb-margin: 0.2rem;
      --toggle-min-width: 4.4rem;
    }
    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    height:var(--toggle-height);
    min-width: var(--toggle-min-width);
    max-width: fit-content;
    position:relative;

  }
  &-spacer {
    transition: all .4s ease-out;
    flex-grow:0;
  }
  &-container {
    background-color:var(--toggle-bg-color-disabled);
    border-radius: 100px;
    height:inherit;
    width:100%;
    position: relative;
    border:1px solid var(--toggle-border-color);
    transition:all 0.4s;
    &.is-checked {
      background-color:var(--toggle-bg-color-enabled);
      .sgh-toggle-thumb {
        background-color:var(--toggle-thumb-color-enabled);
      }
    }
    label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      position: absolute;
      height: 100%;
      top: 0;
      width: inherit;
      cursor:pointer;
      padding: 0 var(--toggle-thumb-margin);
      input {
        display:none;
        &:checked + .sgh-toggle-spacer {
          flex-grow: 1;
        }
      }
    }
  }

  &-text {
    color: var(--toggle-text-color);
    align-content:center;
    text-align:right;
    height:inherit;
    padding-left: 40px;
    padding-right: 24px;
  }

  &-thumb {
    aspect-ratio:1/1;
    height: 80%;
    background-color:var(--toggle-thumb-color-disabled);
    border-radius:100px;
    display:block;
    transition:all 0.4s;
    width:auto;
    cursor:pointer;
  }
}

