/*stylelint-disable*/
@import '../mixins/mixins';

.cms-text-align-center + .product-feed {
  margin-top: 2.4rem;
}

.product-feed {
  @include primaryFont();
  content-visibility: auto;
  contain-intrinsic-size: auto none;
  font-weight: 300;
  font-size: 1.8rem;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.44;
  margin-bottom: 5rem;
  padding: 0 2rem;
  text-align: center;

  @screen md {
    padding: 0 12.5%;
  }

  #plp & {
    font-size: 1.4rem;
    line-height: 1.43;
    margin-bottom: 0;
    padding: 0;
    text-align: unset;

    @screen md {
      padding: 0;
    }

    @screen lg {
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }
  }

  &__title {
    display: block;
    padding: 0 2rem;

    #plp & {
      padding: 0;

      @screen lg {
        padding: 0 2rem;
      }
    }
  }

  &__description {
    @screen mdr {
      margin-bottom: 1.6rem;
    }

    @screen md {
      max-width: 110rem;
      margin: 0 auto 1.6rem;
    }

    #plp & {
      @screen mdr {
        margin-bottom: 0;
      }

      @screen md {
        max-width: none;
        margin: 0;
      }

      @screen lgr {
        margin-bottom: 0.8rem;
        padding: 0 1.6rem;
        width: 97%;
      }

      @screen lg {
        padding-right: 5.2rem;
      }

      &--text {
        margin: 0;
        color: $mine-shaft;
      }

      #moreLessCta-plp-wcs {
        font-weight: 600;
      }
    }
  }

  img {
    vertical-align: top;
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  .sgh-pdp-brand-logo-box {
    display: block;

    #plp & {
      text-align: inherit;

      img {
        height: 7.5rem;
        max-width: none;
        @media (min-width: 1800px) {
          max-width: none;
        }
      }
    }
  }
}
