.sgh-sorting-container {
  min-width: 236px;
  right: 0;
  top: 100%;
  z-index: 2;

  &--hidden {
    @include loading-content();
    background-color: $white;

    &:after {
      opacity: 0.5;
    }
  }
}

.sgh-filters-actions {
  z-index: 11;
  display: flex;

  &__chanel {
    border-bottom: 1px solid #cccccc;
    display: block;
    @screen md {
      border-bottom: none;
    }
  }

  &--mobile {
    box-shadow: 0 2px 4px 0 #dadada;
  }

  &--sort {
    .common__icon--arrow-down {
      margin-top: 2px;
    }

    .common__icon--arrow-up {
      margin-bottom: 1px;
    }

    /* Hide the browser's default radio */
    input[type='radio'] {
      display: none;

      &:checked + span {
        background-color: black;
        border-radius: 9px;
        bottom: 0;
        display: block;
        height: 10px;
        left: 4px;
        margin: auto;
        position: absolute;
        top: 0;
        width: 10px;
      }
    }

    label {
      position: relative;
      padding-left: 27px;
      @screen smr {
        margin-left: 0;
      }
      &:before {
        border: 1px solid #888888;
        border-radius: 9px;
        content: '';
        cursor: pointer;
        display: inline-flex;
        height: 18px;
        position: absolute;
        vertical-align: middle;
        width: 18px;
        left: 0px;
      }
    }
    @screen smr {
      padding: 0.75rem 1.6rem;
    }
  }
}
