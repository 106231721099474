@import '../variables';

.sgh-scroll-categories {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  padding-top: 2rem;
  padding-bottom: 1.6rem;

  .swiper-button-next:after,
  .swiper-button-prev:after {
    content: '';
  }

  .swiper-slide {
    width: auto;
    @screen smr {
      &:first-child {
        margin-left: 1.7rem;
      }
      &:last-child {
        margin-right: 2.5rem;
      }
    }
  }
  .swiper-pagination-progressbar {
    height: 0.2rem !important;
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: $mine-shaft;
  }

  .swiper-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .swiper-wrapper {
    max-width: 100%;
    @screen lg {
      width: auto;
    }
  }

  @screen sm {
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
      width: 6rem;
    }
    .swiper-button-next {
      justify-content: flex-end;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
    .swiper-button-prev {
      justify-content: flex-start;
      background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      display: none;
    }
    padding-top: 1.6rem;
  }
  .common__icon--arrow-left,
  .common__icon--arrow-right {
    height: 1.6rem;
    width: 1.6rem;
  }
}
