.plp-seotext__container {
  position: relative;
  margin: 16px 0;
  border-top: 1px solid $alto-2;

  .plp-seotext_content {
    display: flex;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    padding: 40px 16px;
    font-size: 1.4rem;
    line-height: 2.2rem;
    flex-direction: column;

    b,
    strong {
      font-size: 1.4rem;
    }
    @screen mdr {
      align-items: flex-start;
      font-size: 1.6rem;

      b,
      strong {
        font-size: 1.6rem;
      }
    }

    h2 {
      @include secondaryFont();
      color: $dark-gray;
      font-size: 2.4rem;
      line-height: normal;
      text-align: left;
      @screen mdr {
        font-size: 2.2rem;
      }
    }

    a {
      text-decoration: underline;
    }
  }
}
