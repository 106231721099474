#plp {
  #view-switcher {
    .icon {
      filter: alpha(opacity=20);
      opacity: 0.2;

      &.active {
        filter: alpha(opacity=100);
        opacity: 1;
      }
    }
  }
}
