
@supports (-webkit-touch-callout: none) {
  .fix-cta-ios {
    height: fit-content;
    z-index: 1000;
  }
}

.sgh-filter-by {
  padding-left: 1.6rem;
  padding-right: 2.4rem;
  scroll-behavior: smooth;
  @screen mdr {
    .sgh-filter-by__action--top {
      button {
        @supports (-webkit-touch-callout: none) {
          //ios
          right: 0.7rem;
        }
        right: -0.2rem;
        position: relative;
      }
    }
  }
}

@screen sm {
  .sgh-filter-by {
    padding-left: 0.4rem;
    padding-right: 1.2rem;
  }
}
