
@import '../../../styles/variables';

.faqs-title {
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 2.8rem;
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  letter-spacing: 0.005em;
}

h3 {
  line-height: 1.14;
}

.faqs-container {
  margin: 0rem 27.5rem 1.6rem 27.5rem;

  @screen mdr {
    margin: 1.6rem 3.2rem;
  }

  .faqs-sub-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom: 0.1rem solid $alabaster;
    display: block;

    h4 {
      color: $mine-shaft;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6rem;
      letter-spacing: 0rem;
      text-align: left;
      margin-bottom: 2.6rem;
      margin-top: 2.6rem;
      padding-left: 0.8rem;

      &.no-margin-bottom {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 1.67rem;
      margin-bottom: 2.6rem;
      margin-top: 1.6rem;
      color: $mine-shaft;
      font-weight: 400;
      padding-left: 0.8rem;
    }

    @screen lg {
      display: flex;
      break-inside: avoid-column;
    }
  }

  .common__icon {
    height: 1.2rem;
    width: 1.2rem;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    @screen lg {
      position: relative;
      top: 2.8rem;
      left: 1.5rem;
    }
  }

  @screen lg {
    column-count: 2;
    column-gap: 2.4rem;
  }

  .sgh-load-more {
    @screen mdr {
      margin-top: 2.4rem;
    }
  }

  .view-more-button {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;

    @screen lg {
      margin-top: 2.4rem;
    }
  }
}
