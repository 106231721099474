
.count-filter-desk {
  position: relative;
}
.count-filter-desk::before {
  content: attr(data-count-filter);
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  z-index: 3;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.6rem;
  top: -1rem;
  font-size: 1.4rem;
}
.count-filter-mobile {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.6rem;
  top: -1rem;
  font-size: 1.4rem;
}
