
@import '@styles/mixins/mixins';
@import '@styles/variables';
.sgh-24hrs-filter {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: $mine-shaft;
  padding-left: 2rem;
  &-skeleton {
    display: flex;
    gap: 0.5rem;
    &-element {
      @include skeleton-animation;
      display: block;
      &.toggle {
        width: 4.4rem;
        height: 2.4rem;
        border-radius: 100px;
      }
      &.label {
        width: 8rem;
        height: 2.4rem;
      }
    }
  }
  @screen mdr {
    padding: 0;
  }
}
