/* stylelint-disable */
#plp.sgh-plp .item:hover {
  background-color: $white;
}

#plp.sgh-plp .item .catalog-item {
  position: relative;
}

#plp.sgh-plp .item .catalog-item .sgh-plp__item-icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 13px;
}

#plp.sgh-plp .item .catalog-item .icon {
  position: relative;
  float: left;
  overflow: hidden;
  max-width: 21px;
  height: 21px;
  padding: 0 8px;
  color: $white;
  font-size: 1.1rem;
  line-height: 2.1rem;
  transition: max-width 0.4s ease-in-out 0s;
  border-radius: 21px;
  margin-right: 8px;
}

#plp.sgh-plp .item .catalog-item .icon .icon__label {
  opacity: 0;
  transition: opacity 0.2s ease 0.2s;
}

#plp.sgh-plp .item .catalog-item .icon.engraving {
  background-color: $anzac !important;
  background-image: none !important;
  top: 0;
  left: 0;
  width: auto;
}

#plp.sgh-plp .item .catalog-item .icon.engraving .icon-badge-close-engraving {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2.1rem;
  transition: opacity 0.2s ease 0.2s;
}

#plp.sgh-plp .item .catalog-item .icon.polarized {
  background-color: $black;
}

#plp.sgh-plp .item .catalog-item .icon.polarized:before {
  content: 'P';
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  background: transparent;
  line-height: 2.1rem;
  transition: opacity 0.2s ease 0.2s;
  text-align: center;
}

#plp.sgh-plp .item .catalog-item .icon.polarized:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: 17px;
  border: 1px solid $white;
  background: transparent;
  line-height: 1.7rem;
  transition: opacity 0.2s ease 0.2s;
  border-radius: 21px;
  text-align: center;
}

#plp.sgh-plp .item .catalog-item .icon-wishlist {
  float: right;
  font-size: 3rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease 0.2s;
  cursor: pointer;
  margin-top: -5px;
  margin-right: -5px;
}

#plp.sgh-plp .item .catalog-item:hover .icon-wishlist {
  visibility: visible;
}

#plp.sgh-plp .item .catalog-item .add_to_favor:focus .icon-wishlist {
  visibility: visible;
  opacity: 1;
  transition: opacity 0s 0s;
}

#plp.sgh-plp .item .catalog-item:hover .sgh-plp__item-icon-wrapper .icon,
#plp.sgh-plp .item .catalog-item .sgh-plp__item-icon-wrapper.expanded .icon {
  max-width: 300px;
  transition: max-width 0.7s ease-in-out 0s;
}

#plp.sgh-plp .item .catalog-item:hover .sgh-plp__item-icon-wrapper .icon .icon__label,
#plp.sgh-plp .item .catalog-item .sgh-plp__item-icon-wrapper.expanded .icon .icon__label {
  opacity: 1;
  transition: opacity 0.2s ease;
}

#plp.sgh-plp .item .catalog-item:hover .sgh-plp__item-icon-wrapper .icon.engraving,
#plp.sgh-plp .item .catalog-item .sgh-plp__item-icon-wrapper.expanded .icon.engraving {
  background-color: $anzac;
}

#plp.sgh-plp
  .item
  .catalog-item:hover
  .sgh-plp__item-icon-wrapper
  .icon.engraving
  .icon-badge-close-engraving,
#plp.sgh-plp
  .item
  .catalog-item
  .sgh-plp__item-icon-wrapper.expanded
  .icon.engraving
  .icon-badge-close-engraving {
  opacity: 0;
  transition: opacity 0.2s ease;
}

#plp.sgh-plp .item .catalog-item:hover .sgh-plp__item-icon-wrapper .icon.polarized,
#plp.sgh-plp .item .catalog-item .sgh-plp__item-icon-wrapper.expanded .icon.polarized {
  background-color: $black;
}

#plp.sgh-plp .item .catalog-item:hover .sgh-plp__item-icon-wrapper .icon.polarized:before,
#plp.sgh-plp .item .catalog-item .sgh-plp__item-icon-wrapper.expanded .icon.polarized:before {
  opacity: 0;
  transition: opacity 0.2s ease;
}

#plp.sgh-plp .item .catalog-item:hover .sgh-plp__item-icon-wrapper .icon-wishlist,
#plp.sgh-plp .item .catalog-item .sgh-plp__item-icon-wrapper.expanded .icon-wishlist {
  opacity: 1;
  transition: opacity 0.2s ease;
}

#plp.sgh-plp .item .catalog-item .quick-view {
  display: none !important;
}

#plp.sgh-plp .sgh-plp__load-more {
  text-align: center;
  margin: 20px 0;
}

.body--page-plp-mobile {
  padding-top: 60px !important;
}

#plp.sgh-plp--mobile {
  padding: 30px 15px 32px;
}

#plp.sgh-plp--mobile .sgh-plp__filter--cmd {
  position: relative;
  top: 25px;
  right: 15px;
  padding: 0;
  text-align: right;
}

#plp.sgh-plp--mobile .sgh-plp__filter--cmd .filter__status {
  position: fixed;
  right: 9px;
  border: 0;
  background: 0;
  z-index: 10;
  margin-top: 16px;
  outline: none;
}

#plp.sgh-plp--mobile .sgh-plp__filter--cmd .icon-filter {
  vertical-align: middle;
  line-height: 2.7rem;
  margin-left: 5px;
}

#plp.sgh-plp--mobile .sgh-modal-plp-filters {
  background: $white;
  z-index: 10001;
}

#plp.sgh-plp--mobile .sgh-modal-plp-filters .modal-dialog {
  margin: 0;
}

#plp.sgh-plp--mobile .sgh-modal-plp-filters .modal-body {
  padding: 15px 0;
}

#plp.sgh-plp--mobile .sgh-modal-plp-filters .modal-header {
  padding: 0 21px;
}

#plp.sgh-plp--mobile .sgh-modal-plp-filters .modal-header .icon-logo {
  font-size: 2rem;
  line-height: 5.8rem;
}

#plp.sgh-plp--mobile .sgh-modal-plp-filters .modal-header .close {
  line-height: 5.8rem;
  opacity: 1;
  margin-right: -5px;
}

#plp.sgh-plp--mobile .sgh-modal-plp-filters .modal-header .close span {
  vertical-align: middle;
  color: $black;
  font-size: 2.8rem;
}

#plp.sgh-plp--mobile .sgh-plp__filter-buttons {
  padding: 25px 0;
  text-align: center;
}

#plp.sgh-plp--mobile .sgh-plp__filter-buttons .btn {
  width: 150px;
}

#plp.sgh-plp--mobile .sgh-plp__filter-buttons .btn:first-child {
  margin-right: 10px;
}

#plp.sgh-plp--mobile .sgh-plp__filter__options .panel-section ul li {
  font-size: 1.6rem;
  font-weight: 300;
}

#plp.sgh-plp--mobile .sgh-plp__filter__options .panel-section ul.color-list li a.selected {
  font-weight: 500;
}

#plp.sgh-plp--mobile .item {
  margin-bottom: 15px;
}

#plp.sgh-plp--mobile .item:last-child {
  margin-bottom: 0;
}

#plp.sgh-plp--mobile .item .catalog-item {
  padding: 40px 15px 15px;
  border: 1px solid $alto;
}

#plp.sgh-plp--mobile .item .catalog-item img {
  width: 100%;
}

#plp.sgh-plp--mobile .item .catalog-item .details .sgh-plp__item-icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 13px;
}

#plp.sgh-plp--mobile .item .catalog-item .details .icon {
  position: relative;
  float: left;
  overflow: hidden;
  max-width: 21px;
  height: 21px;
  padding: 0 8px;
  color: $white;
  font-size: 1.1rem;
  line-height: 2.1rem;
  transition: max-width 0.4s ease-in-out 0s;
  border-radius: 21px;
  margin-right: 8px;
}

#plp.sgh-plp--mobile .item .catalog-item .details .icon .icon__label {
  opacity: 0;
  transition: opacity 0.2s ease 0.2s;
}

#plp.sgh-plp--mobile .item .catalog-item .details .icon.engraving {
  background-color: $anzac;
}

#plp.sgh-plp--mobile .item .catalog-item .details .icon.engraving .icon-badge-close-engraving {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2.1rem;
  transition: opacity 0.2s ease 0.2s;
}

#plp.sgh-plp--mobile .item .catalog-item .details .icon.polarized {
  background-color: $black;
}

#plp.sgh-plp--mobile .item .catalog-item .details .icon.polarized:before {
  content: 'P';
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  background: transparent;
  line-height: 2.1rem;
  transition: opacity 0.2s ease 0.2s;
  text-align: center;
}

#plp.sgh-plp--mobile .item .catalog-item .details .icon.polarized:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: 17px;
  border: 1px solid $white;
  background: transparent;
  line-height: 1.7rem;
  transition: opacity 0.2s ease 0.2s;
  border-radius: 21px;
  text-align: center;
}

#plp.sgh-plp--mobile .item .catalog-item .details .icon-wishlist {
  cursor: pointer;
  float: right;
  font-size: 3rem;
  margin-top: -5px;
  margin-right: -5px;
}

#plp.sgh-plp--mobile .item .catalog-item .details p {
  margin-bottom: 3px;
}

#plp.sgh-plp--mobile .item .catalog-item .details .new-badge {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

#plp.sgh-plp--mobile .item .catalog-item .details .brand {
  font-size: 1.8rem;
  text-transform: uppercase;
}

#plp.sgh-plp--mobile .item .catalog-item .details .itemDetails {
  color: $emperor;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: left;
}

#plp.sgh-plp--mobile .item .catalog-item .details .colors-label {
  color: $emperor;
  font-size: 1.2rem;
  margin-top: 20px;
  text-transform: initial;
}

#plp.sgh-plp--mobile .item .catalog-item .details .price {
  float: right;
  color: $black;
  font-size: 1.4rem;
  font-weight: normal;
  margin-top: -17px;
}

#plp.sgh-plp--mobile .item .catalog-item .details .quick-view {
  display: none;
}

#plp.sgh-plp--mobile .item .catalog-item #add_to_cart,
#plp.sgh-plp--mobile .item .catalog-item .addToCart {
  display: none;
}

#plp.sgh-plp--mobile .categoryDisplay {
  width: 50%;
}

#plp.sgh-plp--desktop {
  padding: 0;
  margin-top: 0;
}

#plp.sgh-plp--desktop .tray-holder,
#plp.sgh-plp--desktop .view-area {
  float: none;
}

#plp.sgh-plp--desktop .tray-holder.bottom,
#plp.sgh-plp--desktop .view-area.bottom {
  text-align: center;
  display: inline-block;
}

#plp.sgh-plp--desktop .sgh-plp__left-side {
  float: left;
  width: 315px;
  padding: 20px 0;
}

#plp.sgh-plp--desktop .sgh-plp__right-side {
  float: left;
  width: calc(100% - 315px);
}

#plp.sgh-plp--desktop .sgh-plp__right-side.noresult {
  float: none;
  width: 95%;
  margin: 0 auto !important;
}

#plp.sgh-plp--desktop .sgh-plp__dropdowns {
  position: relative;
  width: auto;
  padding: 20px 20px 0;
}

#plp.sgh-plp--desktop .sgh-plp__dropdowns .sort-by {
  position: fixed;
  top: 115px;
  right: 20px;
  z-index: 11;
  margin-top: 20px;
}

#plp.sgh-plp--desktop .sgh-plp__dropdowns .sort-by .dropdown-toggle {
  position: relative;
  border: none;
  background: none;
  padding-right: 27px;
}

#plp.sgh-plp--desktop .sgh-plp__dropdowns .sort-by .dropdown-toggle > span {
  position: absolute;
  top: 1px;
  right: 0;
  font-size: 1.7rem;
}

#plp.sgh-plp--desktop .sgh-plp__dropdowns .sort-by .dropdown-menu {
  border-radius: 0;
  left: -95%;
  margin-top: 6px;
}

#plp.sgh-plp--desktop .sgh-plp__dropdowns .sort-by .dropdown-menu > li > a {
  padding: 5px 20px;
}

#plp.sgh-plp--desktop .sgh-plp__dropdowns .sort-by .dropdown-menu > li > a:hover,
#plp.sgh-plp--desktop .sgh-plp__dropdowns .sort-by .dropdown-menu > li > a:focus {
  background-image: none;
  background-color: $concrete;
}

#plp.sgh-plp--desktop .sgh-plp__dropdowns .number-results {
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: normal;
}

#plp.sgh-plp--desktop .sgh-plp__filter--cmd .icon-filter {
  vertical-align: top;
  margin-right: 5px;
  margin-left: 5px;
}

#plp.sgh-plp--desktop .item.plp {
  position: relative;
  float: left;
  display: block;
  padding: 33.33333% 0 0;
  text-align: left;
}

#plp.sgh-plp--desktop .item.plp .thumbnailWrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;
}

#plp.sgh-plp--desktop .item.plp .img-container {
  display: flex;
  height: 100%;
  padding: 0;
}

#plp.sgh-plp--desktop .item.plp .catalog-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px 12px 12px;
  border: 1px solid $alto;
  transition: box-shadow 0.3s ease;
  flex-direction: column;
}

#plp.sgh-plp--desktop .item.plp .catalog-item img {
  width: 100%;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .main-img {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 2;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .frontView_Image,
#plp.sgh-plp--desktop .item.plp .catalog-item .armView_Image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .details p {
  margin-bottom: 3px;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .details .new-label {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .details .brand {
  font-size: 1.8rem;
  text-transform: uppercase;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .details .style {
  color: $emperor;
  font-size: 1.2rem;
  text-transform: uppercase;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .details .colors-label {
  color: $emperor;
  font-size: 1.2rem;
  margin-top: 20px;
  text-transform: initial;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .details .price {
  float: right;
  color: $black;
  font-size: 1.4rem;
  margin-top: -23px;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .details .quick-view {
  display: none;
}

#plp.sgh-plp--desktop .item.plp .catalog-item:hover {
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
}

#plp.sgh-plp--desktop .item.plp .catalog-item .armView_Image {
  display: block;
}

#plp.sgh-plp--desktop .item.plp .catalog-item .frontView_Image {
  display: none;
}

#plp.sgh-plp--desktop .item.plp .catalog-item:hover .armView_Image {
  display: none;
}

#plp.sgh-plp--desktop .item.plp .catalog-item:hover .frontView_Image {
  display: block;
}

@media screen and (min-width: 1024px) {
  #plp.sgh-plp--desktop .item.plp {
    width: 33.33333%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #plp.sgh-plp--desktop .item.plp {
    width: 50%;
    padding: 50% 0 0;
  }
}

.modal-backdrop.in {
  z-index: 100;
}
