/* stylelint-disable */
$buttonEasing: ease-out;

@media (min-width: 768px) and (max-width: 1023px) {
  #plp {
    .item {
      .quick-view {
        display: block !important;
      }

      &:hover,
      &:focus {
        .quick-view {
          .redesignIcons-minus {
            display: inline-block !important;
          }

          .redesignIcons-plus {
            display: none !important;
          }
        }
      }
    }
  }

  #main-navigation-container {
    .do-close {
      top: 20px;
    }
  }
}

@screen xl {
  #plp,
  #trends {
    .item {
      &:hover {
        .img-container {
          .cart {
            width: 152px;
            visibility: visible;
            transition: width 0.3s $buttonEasing 0s, text-indent 0.4s $buttonEasing 0s,
              visibility 0s ease 0s;
            text-indent: 40px;
          }
        }
      }

      &.hto {
        &:hover {
          .img-container {
            .cart {
              width: auto;
              visibility: hidden;
              transition: none;
              text-indent: 0;
            }
          }
        }
      }

      .img-container {
        .cart {
          display: inline-block !important;
          overflow: hidden;
          width: 50px;
          height: 25px;
          padding: 10px 0;
          color: $white;
          line-height: 2.5rem;
          white-space: nowrap;
          visibility: hidden;
          transition: width 0s $buttonEasing 0s, text-indent 0s $buttonEasing 0s,
            visibility 0s ease 0s;
          text-indent: 50px;
        }
      }
    }

    .redesignIcons-bag {
      margin-top: 0;
      position: absolute;
      top: 12px;
      left: 15px;
    }

    .redesignIcons-engraving {
      background: $di-serria;
    }
  }
}
