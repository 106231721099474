/* This code is relative to JSP files */
@import '../mixins/mixins';

#plp {
  position: relative;
  padding: 0 50px;

  .item {
    &.plp {
      @include boxSizingBorderBox();
      float: none;
      vertical-align: top;
      display: inline-block;
      padding: 0;
      margin-bottom: 5px;
      min-height: inherit;
      zoom: 1;
      -moz-background-clip: padding-box;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;

      .img-container {
        position: relative;
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 40px 0 65px;
        min-height: inherit;
      }
    }

    &.full {
      @include boxSizingBorderBox();
      float: none;
      vertical-align: top;
      display: inline-block;
      width: 100%;
      margin-bottom: 5px;
      min-height: inherit;
      zoom: 1;
      -moz-background-clip: padding-box;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &.espot {
      display: inline-block;
      min-height: inherit;
    }

    .container {
      float: none;
      vertical-align: top;
      display: inline-block;
      width: 33%;
      margin-bottom: 5px;
      min-height: inherit;
      zoom: 1;
      -moz-background-clip: padding-box;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      @include boxSizingBorderBox();

      .item {
        float: none;
        width: 100%;
        padding: 0;
        min-height: inherit;
      }
    }

    p {
      .badge {
        margin: 0 auto;
      }
    }

    .icon {
      &.new {
        top: 5px;
      }
    }

    .redesignIcons-favorites-red,
    .compare {
      top: 20px;
    }

    .quick-view {
      position: relative;
      bottom: inherit;
      left: inherit;
      margin: 25px 0 0;
    }

    .details {
      p {
        &.gray {
          margin-top: 10px;
        }
      }
    }
  }

  .height-reveal {
    overflow: hidden;
    height: auto !important;
    margin: 0 0 10px;
    clear: both;

    img {
      width: 100%;
    }
  }

  .products {
    li {
      width: 97% !important;
    }
  }

  .search-term {
    margin: 0 0 50px;
    text-align: center;
    clear: both;
  }

  .view-area {
    float: left;
    width: 100%;

    .dropdowns {
      width: 680px;
      margin: 0 auto;

      &.bottom {
        width: 500px;
        height: 95px;
      }

      .number-results {
        float: left;
        margin: 0 25px 0 0;
        line-height: 4.7rem;
      }

      ul {
        margin: 0 10px 0 0;

        &.page {
          float: left;
          width: 130px;
        }

        &.sort-by {
          float: left;
          width: 195px;
        }
      }
    }

    .pagination {
      width: 280px;
      margin: 0 auto;
      padding: 20px 0 30px;
      clear: both;

      a {
        &.pager {
          margin: 0 0 0 35px;
          text-decoration: underline;
          font: {
            style: italic;
          }

          &:first-child {
            margin: 0 35px 0 0;
          }

          &.disabled {
            display: none;
          }
        }
      }

      span {
        margin: 0 2px;
        color: #555;
        font: {
          size: 9px;
          style: italic;
        }
      }
    }

    &.bottom {
      .pagination {
        padding: 20px 0 67px;
      }
    }

    #view-switcher {
      float: right;
      margin: -39px 40px 0 0;

      .icon {
        display: inline-block;
        margin: 0 0 0 10px;
        opacity: 0.2;
        text-indent: -9999px;

        &:first-child {
          margin: 0;
        }

        &.active {
          opacity: 1;
          cursor: default;
        }
      }

      #quarter-view {
        position: relative;
        top: -4px;
      }
    }
  }

  .compare-button {
    border-width: 3px;
    text-decoration: none;

    .tooltipContent {
      bottom: 35px;
      left: 4px;
    }
  }

  .tray-holder {
    float: left;
    padding-right: 17px;
    position: relative;
    width: 100%;
  }
}

ul.list-unstyled.color-list .img-circle {
  margin-right: 12px;
}

#plp .catalog-item .details p.badge {
  background: none;
  color: $black;
  text-transform: uppercase;
}
