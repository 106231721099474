.sgh-filter-item {
  padding-left: 1.6rem;
  &__list {
    /* Hide the browser's default checkbox */
    input[type='checkbox'] {
      cursor: pointer;
      margin: 0;
      opacity: 0;
      position: absolute;
      height: 2.5rem;
      width: 2.5rem;

      ~ .sgh-checkbox__checkmark {
        vertical-align: middle;
        height: 14px;
        width: 14px;
        border: 1px solid black;
        display: inline-block;
        border-radius: 0.3rem;

        &:after {
          content: '';
          font-size: 1.2rem;
          line-height: 1.2rem;
          margin: 1px;
        }
      }

      /* When the checkbox is checked, add a blue background */
      &:checked ~ .sgh-checkbox__checkmark {
        background-color: black;

        /* Show the checkmark when checked */
        &:after {
          display: block;
        }
      }
    }
    .custom-slider {
      scroll-behavior: smooth;
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      .left,
      .right {
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s linear;
        position: absolute;
        width: 4rem;
        height: 4rem;
        background-color: $white;
        border-radius: 50%;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        z-index: 2;
        span {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
      .left {
        left: -2rem;
      }
      .right {
        right: -2rem;
      }
      .visible-arrow {
        visibility: visible;
        opacity: 1;
      }
      .gradient-left,
      .gradient-right {
        width: 31px;
        height: 100%;
        position: absolute;
        z-index: 2;
      }
      .gradient-left {
        left: 0;
        background: linear-gradient(to right, #fff 39%, rgba(255, 255, 255, 0) 105%);
      }
      .gradient-right {
        right: 0;
        background: linear-gradient(to left, #fff 39%, rgba(255, 255, 255, 0) 105%);
      }
    }
  }

  &__desc {
    display: block;
    margin-left: 25px;
    line-height: 1.4rem;
  }

  &__list__item {
    padding-left: 0.6rem;
    &--disabled {
      color: $emperor;
      opacity: 0.5;
    }

    &--hidden {
      display: none !important;
    }
    .common__icon {
      &--dash-button {
        height: 3.2rem;
        width: 3.2rem;
        @screen sm {
          margin-left: 0.2rem;
        }
      }

      &.frameShapeFacet,
      &.attributes_translated\.FRAME_SHAPE_FACET {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }

  &__dash__item {
    padding-right: 1.6rem;
    padding-left: 0.4rem;
    &--checked {
      background-color: $black;
      label {
        color: $white;
      }
    }
  }
}

.sgh-filter-action {
  @screen smr {
    padding-left: 0;
  }
  &__label {
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
