
@import '../../../../styles/variables';

.sgh-filters-actions {
  &__chanel {
    padding: 2.4rem 1.6rem 1.6rem;

    @screen md {
      display: flex;
      flex-direction: column;
      padding: 4rem 4rem 2.4rem 4rem;
    }
  }

  .sgh-24hrs-filter {
    margin-top: 1.6rem;

    @screen md {
      margin-top: 0;
    }
  }

  .results-text {
    text-align: left;
    line-height: 2.1rem;
  }

  .filters-container {
    width: 100%;
  }

  .filters {
    @screen md {
      display: flex;
      width: 100%;
    }

    button {
      line-height: 2rem;
    }
  }

  .count-filter-desk::before {
    content: attr(data-count-filter);
    display: block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    z-index: 3;
    background: $black;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -1.2rem;
    top: -1rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }

 
    .count-filter-mobile {
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: $black;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0.6rem;
      top: -1rem;
      font-size: 1.4rem;
      margin-left:0.8rem;
      line-height:2rem;
    }
  
}
