
.container {
  &.sgh-grid {
    margin-top: 0;

    .sgh-col {
      margin-bottom: 7rem;

      @screen smr {
        margin-bottom: 2.7rem;
      }
    }
  }
}
