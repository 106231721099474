.sgh-grid {
  max-width: 100%;
  padding: 0;

  @screen lg {
    margin-top: 1.6rem;
  }
}

.sgh-row {
  display: flex;
  flex-wrap: wrap;

  @screen sm {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.sgh-col {
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  @screen sm {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__custom {
    &--banner {
      @screen smr {
        margin: 25px 0;
      }
    }
  }
}

.sgh-plp {
  &-container {
    position: relative;
  }
  &__chanel {
    &--banner {
      min-height: 212px;

      @screen sm {
        height: 309px;
      }
    }

    &--img {
      border-top: 6px solid black;

      @screen sm {
        border-top: 8px solid black;
      }
    }
  }
}

.plp-hidden {
  display: none;
}

@supports (-webkit-touch-callout: none) {
  .fix-ios {
    bottom: 8%;
    position: fixed;
  }
}

.sgh-tile-chanel__img-container {
  img {
    max-height: 100%;
    object-fit: cover;
  }
}
