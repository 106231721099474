

.sgh-filter-accordion {
  .sgh-filter-action {
    outline: none !important;
    &:focus-visible {
      outline: auto !important;
    }
  }

  &-closed {
    max-height: 3.7rem;
    animation: closeAccordion 0.35s linear;
  }

  &-opened {
    animation: openAccordion 0.35s linear;
    scroll-margin-top: 1.1rem;
    overflow: clip;
    @screen mdr {
      scroll-margin-top: 5.6rem;
    }
  }
}

@keyframes openAccordion {
  0% {
    max-height: 0;
  }
  100% {
    max-height: var(--scroll-height);
  }
}

@keyframes closeAccordion {
  0% {
    max-height: var(--scroll-height);
  }
  100% {
    max-height: 3.7rem;
  }
}

.sgh-filter-item__list__item {
  padding-left: 0.6rem;
}

@screen sm {
  .sgh-filter-item__list__item {
    padding-left: 0;
  }
}

#mobile-next {
  z-index: 0;
}

.sgh-dash-filters {
  @screen smr {
    padding-left: 1.6rem;
  }

  label {
    text-transform: lowercase;
    font-size: 1.4rem;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
