.sgh-sidebar {
  z-index: 20002;
  height: 100%;
  height: calc((var(#{--vhheader}, 1%) * 100));

  &__panel,
  &__overlay {
    height: inherit;
  }

  &__panel {
    animation: slide-in 0.6s ease-in 1;
    &-close {
      animation: slide-out 0.6s ease-out 1;
    }

    .sgh-filter-by {
      height: 100%;

      &__action {
        &--top {
          position: sticky;
          top: 16px;

          @screen lgr {
            height: 4.8rem;
            margin-top: 0.8rem;
            margin-bottom: 0.8rem;
            margin-right: 1.6rem;
            position: fixed;
            width: 100%;
            right: 0;
            top: 0;
          }
        }
      }
    }

    @screen sm {
      min-width: 500px;
      box-shadow: -3px -2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    @keyframes slide-in {
      0% {
        transform: translateX(+500px);
      }
      100% {
        transform: translateX(0);
      }
    }
    @keyframes slide-out {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(+500px);
      }
    }
  }
}
